import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BuildNumber from '../../metadata.json';
import "./DefaultFooter.css";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span><a href="https://iot-tech.cz">&copy; 2019 IoT Technology s.r.o.</a></span>
        <span className="ml-auto">Developed by <a href="https://infowebsro.cz">INFO WEB s.r.o.</a></span> &nbsp;<div className='footerBuild'>{BuildNumber.build}</div> 
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
