/* jshint esversion: 11 */
import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container, Spinner } from 'reactstrap';
import API from "../../utils/api";
import { CompressorRoomChanger } from '../../views/Dashboard/CompressorRoomChanger';
import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigationEn from '../../_nav';
import navigationCs from '../../_navCs';
// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledSpinner = styled.div`
    z-index: 90;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    padding-top: 50vh;
    padding-left: 50vw;
`;

export default function DefaultLayout(props) {

  const [rooms, setRooms] = useState([]);
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [actRoom, setActRoom] = useState(1);
  const [compressorIds, setCompressorIds] = useState('');
  const [compressorNames, setCompressorNames] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [macAddress, setMacAdress] = useState('');
  const { t, i18n } = useTranslation();

  const signOut = useCallback( async () => {
    setIsLoading(true);

    await API.post(`/logout/`, '', { withCredentials: true, headers: { 'x-auth-token': JSON.parse(localStorage.getItem('currentUser')).user.token } })
      .then(res => {
        localStorage.removeItem('currentUser');
        // userHasAuthenticated(false);
        props.history.push('/login');
        setIsLoading(false);
      })
      .catch(error => {

        if (error.response.status !== 401) {
          console.log("Cannot logout on API");
        }
        setIsLoading(false);
      });
  }, [props.history]);
  
  const getMe = useCallback( () => {

    API.get(`/users/get/`, { withCredentials: true, headers: { 'x-auth-token': JSON.parse(localStorage.getItem('currentUser')).user.token } })
      .then(res => {

        if (res.data.company_id !== companyId) {

          setActRoom(1);
          setCompanyId(res.data.company_id);
          setCompanyName(res.data.company_names[res.data.company_id]);
          setRooms(res.data.rooms);
          setCompressorIds(res.data.compressor_id);
          setCompressorNames(res.data.compressor_names);
          setMacAdress(res.data.mac_address);
        }
      })
      .catch(error => {
        console.log("Problems on API getMe, signing out");
        signOut();
        if (error.response.status !== 401) {
          console.log("Cannot get data from API, signing out");
          signOut();
        }
      });
  }, [companyId, signOut]);

  useEffect(() => {
    setIsLoading(true);
    getMe();
    setIsLoading(false);
  }, [getMe]);

  function onRoomChange(currentRoom) {
    setActRoom(currentRoom.value);
  }

  const trimmedNavConfig = (i18n.language?.includes("cs") ? navigationCs : navigationEn)

  const newRoutes = routes.map((route, idx) => {
    route.name = t(route.name);
    return route;
  });

  return (
    <div className="app">
      {isLoading && <StyledSpinner> <Spinner color="primary" style={{ width: '6rem', height: '6rem' }} /> </StyledSpinner>}
      <AppHeader fixed>
        <DefaultHeader onLogout={signOut} companyName={companyName} compressorIds={compressorIds} />
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <Suspense>
            <AppSidebarNav navConfig={trimmedNavConfig} {...props} router={router} />
          </Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb appRoutes={newRoutes} router={router} />
          <CompressorRoomChanger handleChange={onRoomChange} rooms={rooms} actRoom={actRoom} />
          <Container fluid>
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component {...props} companyId={companyId} rooms={rooms} macAddress={macAddress} reloadMe={getMe} actRoom={actRoom} compressorNames={compressorNames} compressorIds={compressorIds} />
                    )} />
                ) : null;
              })}
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </Container>
        </main>
        <AppAside fixed>
          <DefaultAside />
        </AppAside>
      </div>
      <AppFooter>
        <DefaultFooter />
      </AppFooter>
    </div>
  );
}