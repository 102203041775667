import React, { useState, useEffect, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Badge,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  ListGroup,
  ListGroupItem,
  CardFooter,
} from "reactstrap";
import API from "../../utils/api";
import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/brand/logo.svg";
import sygnet from "../../assets/img/brand/sygnet.svg";
import "./DefaultHeader.css";
import Avatar from "../../assets/img/Avatar.png";
//const currentUser = JSON.parse(localStorage.getItem('currentUser'));

//https://localhost:8443/v9/alerts.php?company_id=6&room=2&compressor_id=16
//https://api.comproportal.com/v7/alerts.php?compressor_id[]=15&compressor_id[]=16&compressor_id[]=17&compressor_id[]=18&compressor_id[]=19&compressor_id[]=20

export default function DefaultHeader(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [compressorIds, setCompressorIds] = useState(["aa"]);

  const [alerts, setAlerts] = useState([]);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const { t, i18n } = useTranslation("");

  const ajaxPeriodical = useCallback( () => {
    var url = "/alerts.php?compressor_id[]=" + compressorIds;
    API.get(url, { withCredentials: true, headers: { "x-auth-token": token } })
      .then((response) => {
        setAlerts(response.data.alerts);
      })
      .catch((error) => {
        console.log("Error during API call in periodical alerts");
      });
  }, [compressorIds, token]);

  useEffect(() => {
    if (localStorage.getItem("currentUser")) {
      setEmail(JSON.parse(localStorage.getItem("currentUser")).user.email);
      setToken(JSON.parse(localStorage.getItem("currentUser")).user.token);
      setFirstName(
        JSON.parse(localStorage.getItem("currentUser")).user.firstname
      );
      setLastName(
        JSON.parse(localStorage.getItem("currentUser")).user.lastname
      );
    }

    const timerID = setInterval(
      () => ajaxPeriodical(),
      60000 //Every minute
    );

    return () => {
      clearInterval(timerID);
    };
  }, [ajaxPeriodical, props]);

  useEffect(() => {

    setCompressorIds(() => props.compressorIds);

    var url = "/alerts.php?compressor_id[]=" + props.compressorIds;
    API.get(url, { withCredentials: true, headers: { "x-auth-token": token } })
      .then((response) => {
        setAlerts(response.data.alerts);
      })
      .catch((error) => {
        console.log("Error during API call in periodical alerts");
      });
  }, [props.compressorIds, token]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  let severityIcon = "";
  const alertList = alerts.map((alert, index) => {
    if (alert.severity === "high") {
      severityIcon = "fa fa-exclamation-triangle text-red";
    }
    if (alert.severity === "medium") {
      severityIcon = "fa fa-bell-o text-yellow";
    }
    if (alert.severity === "low") {
      severityIcon = "fa fa-info-circle";
    }
    return (
      <DropdownItem key={index} className="alertList">
        <i className={severityIcon}></i>{" "}
        <small className="text-muted">{alert.text}</small>
        <div className="timestamp pull-right">
          <small className="text-muted">{alert.timestamp}</small>
        </div>
      </DropdownItem>
    );
  });

  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <AppNavbarBrand
        full={{ src: logo, width: 89, height: 35, alt: "ComPro portal" }}
        minimized={{ src: sygnet, width: 30, height: 40, alt: "ComPro portal" }}
      />
      <div className="behindToggle">
        <AppSidebarToggler className="d-md-down-none" display="lg" />
      </div>
      <Nav className="d-md-down-none" style={{ color: "blue" }} navbar>
        <NavItem className="px-3">
          <NavLink to="/dashboard" className="nav-link">
            {t("Dashboard")}
          </NavLink>
        </NavItem>
        <NavItem className="px-3">
          <Link to="/users" className="nav-link">
            {t("Users")}
          </Link>
        </NavItem>
      </Nav>
      <div className="companyTitle">{props.companyName}</div>
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown>
          <DropdownToggle caret color="white">
            {i18n.language}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={() => changeLanguage("cs")}>cs</DropdownItem>
            <DropdownItem onClick={() => changeLanguage("en")}>en</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown nav direction="down">
          <DropdownToggle nav>
            <i className="icon-bell"></i>
            <Badge pill color="danger">
              {alerts.length}
            </Badge>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header tag="div" className="text-center blue">
              <strong>{t("Alerts")}</strong>
            </DropdownItem>
            {alertList}
            <CardFooter className="px-3 py-2 alerts">
              <a
                className="font-weight-bold font-xs btn-block blue"
                href="/history/alerts"
              >
                {t("All active and inactive alerts")}
                <i className="fa fa-angle-right float-right font-lg"></i>
              </a>
            </CardFooter>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown nav direction="down">
          <DropdownToggle nav>
            <img src={Avatar} className="img-avatar" alt="User icon" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header tag="div" className="text-center">
              <strong>{t("Account")}</strong>
            </DropdownItem>

            <ListGroup className="list-group-accent" tag={"div"}>
              <ListGroupItem
                action
                tag="a"
                href="#"
                className="list-group-item list-group-item-divider"
              >
                <div>{firstName + " " + lastName} </div>
                <small className="text-muted">
                  <i className="icon-envelope"></i> {email}
                </small>
              </ListGroupItem>
            </ListGroup>

            <DropdownItem onClick={(e) => props.onLogout(e)}>
              <i className="fa fa-lock"></i>
              {t("Logout")}{" "}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>

      <p>&nbsp;&nbsp;&nbsp;</p>
      {/*<AppAsideToggler className="d-lg-none" mobile />*/}
    </React.Fragment>
  );
}
