/* eslint-disable import/no-anonymous-default-export */
export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'History',
      url: '/history',
      icon: 'icon-bell',
      children: [
        {
          name: 'Alert history',
          url: '/history/alerts',
          icon: 'icon-bell',
        },
        {
          name: 'Service history',
          url: '/history/service',
          icon: 'icon-bell',
        },
      ],
    },
    {
      name: 'Graphs',
      url: '/graphs',
      icon: 'icon-pie-chart',
      children: [
        {
          name: 'Power',
          url: '/graphs/power',
          icon: 'icon-pie-chart',
        },
        {
          name: 'Temperature',
          url: '/graphs/temperature',
          icon: 'icon-pie-chart',
        },
        {
          name: 'Pressure',
          url: '/graphs/pressure',
          icon: 'icon-pie-chart',
        },
        {
          name: 'Air Quantity',
          url: '/graphs/air_quantity',
          icon: 'icon-pie-chart',
        }
      ],
    },
    {
      name: 'Statistics',
      url: '/statistics',
      icon: 'icon-graph',
      children: [
        {
          name: 'Daily',
          url: '/statistics/daily',
          icon: 'icon-graph',
        },
        {
          name: 'Weekly',
          url: '/statistics/weekly',
          icon: 'icon-graph',
        },
        {
          name: 'Monthly',
          url: '/statistics/monthly',
          icon: 'icon-graph',
        },
      ],
    },
    {
      name: 'Users',
      url: '/users',
      icon: 'icon-people',
     
    },
    {
      divider: true,
    },
  ],
};
