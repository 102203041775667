import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./CompressorRoomChanger.css";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    color: "#045a89",
    borderRadius: "3px",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#045a89" : isFocused ? null : null,
      color: isSelected ? "white" : isFocused ? "#045a89" : "#045a89",
      cursor: isDisabled ? "not-allowed" : "default",
      ":hover": {
        color: "white",
        backgroundColor: "#067bba",
      },
      ":active": {
        color: "white",
        backgroundColor: !isDisabled && (isSelected ? "#045a89" : "#045a89"),
      },
    };
  },

  singleValue: (styles) => ({ ...styles }),
};

export function CompressorRoomChanger(props) {
  const [newRooms, setNewRooms] = useState([]);

  var settingRooms = props.rooms.map((obj) => {
    var newObj = {};

    newObj.value = obj.id;
    newObj.label = obj.name;

    return newObj;
  });
  
  const sortedSettingRoom = settingRooms.sort(function (a, b) {
    var keyA = new Date(a.value),
      keyB = new Date(b.value);

    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  useEffect(() => {
    if (newRooms.length < 1) {
      setNewRooms(sortedSettingRoom);
    }
  }, [newRooms.length, props.rooms, sortedSettingRoom]);

  function handleChange(selectedOption) {
    props.handleChange(selectedOption);
  }

  var actRoom = props.actRoom;

  return (
    <div className="compressor-room-changer">
      <Select
        className="compressor-select"
        classNamePrefix="comprosel"
        value={newRooms[actRoom - 1]}
        onChange={handleChange}
        options={newRooms}
        theme={(theme) => ({
          ...theme,
          borderRadius: 1,
          colors: {
            ...theme.colors,
            primary25: "#067bba",
            primary: "#045a89",
          },
        })}
        styles={colourStyles}
      />
    </div>
  );
}
