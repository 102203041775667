import { useContext, createContext } from "react";

const ctx = 
  {
    userHasAuthenticated: null,
    isAuthenticated: false,
    token: null
  }

export const AppContext = createContext(ctx);

export function useAppContext() {
  return useContext(AppContext);
}
