/* eslint-disable import/no-anonymous-default-export */
export default {
  items: [
    {
      name: 'Přehled',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Historie',
      url: '/history',
      icon: 'icon-bell',
      children: [
        {
          name: 'Historie hlášení',
          url: '/history/alerts',
          icon: 'icon-bell',
        },
        {
          name: 'Historie servisu',
          url: '/history/service',
          icon: 'icon-bell',
        },
      ],
    },
    {
      name: 'Grafy',
      url: '/graphs',
      icon: 'icon-pie-chart',
      children: [
        {
          name: 'Výkonu',
          url: '/graphs/power',
          icon: 'icon-pie-chart',
        },
        {
          name: 'Teploty',
          url: '/graphs/temperature',
          icon: 'icon-pie-chart',
        },
        {
          name: 'Tlaku',
          url: '/graphs/pressure',
          icon: 'icon-pie-chart',
        },
        {
          name: 'Dodávaného vzduchu',
          url: '/graphs/air_quantity',
          icon: 'icon-pie-chart',
        }
      ],
    },
    {
      name: 'Statistiky',
      url: '/statistics',
      icon: 'icon-graph',
      children: [
        {
          name: 'Denní',
          url: '/statistics/daily',
          icon: 'icon-graph',
        },
        {
          name: 'Týdenní',
          url: '/statistics/weekly',
          icon: 'icon-graph',
        },
        {
          name: 'Měsíční',
          url: '/statistics/monthly',
          icon: 'icon-graph',
        },
      ],
    },
    {
      name: 'Uživatelé',
      url: '/users',
      icon: 'icon-people',
     
    },
    {
      divider: true,
    },
  ],
};
